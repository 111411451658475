<template>
  <div id="setUp">
    <van-nav-bar title="钱包" :border="false" left-arrow @click-left="goLastPage">
    </van-nav-bar>
    <div class="brand">
      <img src="../../../assets/images/logo.png" alt="" />
      <p>亿师傅师傅版 V1.0</p>
    </div>
    <div class="setWrap">
      <van-cell title="协议与规则" is-link url="/setup" size="large" />
      <van-cell title="关于我们" is-link to="setup" size="large" />
    </div>
    <div class="setWrap">
      <span class="outLogin" @click="outLogin">退出登录</span>
    </div>
  </div>
</template>
<script>
import store from "@/store/index.js";
import { Toast } from "vant";
import TopBar from "@/components/home/TopBar.vue";

export default {
  components: {
    TopBar,
  },
  methods: {
    goLastIndex() {
      this.$router.go(-1);
    },
    outLogin() {
      this.$http
        .get(`${this.http_axios}/api/v2/worker/auth/logout`)
        .then((res) => {
          if (res.data.code === 0) {
            window.localStorage.clear();
            this.goSubPageR("login");
          }
        });
    },
  },
};
</script>
<style lang="less">
#setUp {
  .brand {
    width: 100%;
    // padding: 8rem 0 12rem 0;
    padding: 4rem 0 4rem 0;
    background: #ff7963 url("../../../assets/1.png") no-repeat bottom / cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 40%;
    }
    p {
      font-size: 1.4rem;
      color: #fff;
      padding: 0.5rem;
    }
  }
  .setWrap {
    width: 100%;
    // height: 20rem;
    margin: 1rem 0;
    background: #fff;
  }
  .outLogin {
    height: 4rem;
    display: block;
    font-size: 1.6rem;
    text-align: center;
    line-height: 4rem;
  }
}
</style>